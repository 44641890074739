<template>
<div class="modal">
    <div class="modal-inner">
        <h2 class="modal-h2">Registration Begins This Friday</h2>
        <!-- <img class="logo" src="@/assets/gap-lion-logo1.png" /> -->
        <div class="modal-content">
            <p>Thank you for your interest in<br>the Great Awakening Project Leaders Gathering 2021!</p>
            <p>Registration for this event begins Friday, August 6th.</p>
            <p>Please check back on Friday.</p>
        </div>
        <div class="modal-controls">See you in Birmingham in September!</div>
    </div>
</div>
</template>

<script>
export default {
  name: 'modal'
}
</script>

<style scoped lang="scss">
.modal {
    position: fixed;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    align-items: center;
	justify-items: center;
	.modal-loading {
		text-align: center;
		img { max-width: 5em; animation: color_change 10s infinite; }
	}
    .modal-inner {
	    background: white;
	    box-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.2);
	    padding: 0;
		max-height: 30em;
		max-width: 40em;
	}
	h2.modal-h2 {
		margin-top: 0;
		color: #666;
	    font-size: 1.2em;
	    text-transform: uppercase;
	    letter-spacing: 2px;
	    text-align: center;
	    background: #e4e4e4;
	    padding: 1em;
	}
	.modal-content {
		padding: 0.5em 2em;
		max-height: 20em;
		overflow-x: scroll;
	}
	.modal-title {
		font-weight: bold;
		margin: 0 0 1em 0;
	}
	ul.modal-ul {
		list-style: none;
		li { padding: 0.25em; }
	}
	.modal-empty {
		padding: 2em 0;
		text-align: center;
		color: #999;
	}
	.modal-note {
		font-size: 0.9em;
		padding: 0 0 1em 0;
		text-align: center;
	}
	.modal-alert { padding: 0.5em; color: #fff; }
	.modal-controls {
		width: 100%;
	    margin: 0 auto;
	    padding: 1em;
	    background: #e4e4e4;
		text-align: center;
		.button { height: auto; }
	}
	.modal-result { margin: 1em 0 0 0; text-align: center; }
}
</style>
