<template>
<div class="main content-page register" id="page_top">

	<div class="content-sidebar event-description" style="font-family: Arial, sans-serif;">
		<h2 v-if="!registrationSent">{{ eventTitle }}</h2>

		<h3 v-if="!registrationSent" style="color: #933b0f;">October 25-26, 2023</h3>
		<p v-if="!registrationClosed && !registrationSent">
			You are invited by <b>The Great Awakening Project</b> to the <b>Dallas Ft. Worth area</b> on <b>October 25-26, 2023</b>, for an <b>exclusive event</b> to update you on what we are doing to usher in Revival and Awakening in America. Our focus will include City Impact Initiatives transforming cities nationwide. See details below. Please join us!
		</p>

		<div v-if="!registrationClosed && !registrationSent">

			<h4 style="color: #db971b;">Date / Time / Venue</h4>
			<p>
				The event begins with <b>lunch</b> at <b>12:00 noon Central on Wednesday, October 25</b>, in the <b>Trinity Ballroom</b> at <b>The Westin Dallas Fort Worth Airport Hotel</b>, located at 4545 W. John Carpenter Freeway, Irving, TX 75063. The event ends with lunch by <b>1:00 pm</b> on <b>Thursday, October 26</b>.
			</p>
			<h4 style="color: #db971b;">Basic Schedule</h4> 
 			<b>Wednesday, Oct 25</b>
			<p>
			<ul>
				<li>Welcome Lunch and Introduction</li>
				<li><b>Ford Taylor</b> with City Impact Transformational Experience and Updates</li>
				<li>Dinner Keynote by <b>Nick Vujicic</b> with a REVIVAL Word</li>
			</ul>
			</p>

			<b>Thursday, Oct 26</b>
			<p>
				<ul>
					<li>Breakfast Devotional</li>
					<li>PANEL Values-Centric Economy: <b>Kevin Freeman, Glenn Story</b>, and other leaders in business and industry</li>
					<li>PANEL Collective Impact Alliances: Education, Church Engagement, Gen Now, Election Integrity, Prison Ministry (leaders of collaborative efforts in cultural spheres)</li>
					<li><b>Lance Wallnau</b> with an AWAKENING message</li>
					<li>Departing Lunch and Networking Opportunities</li>
				</ul>
			</p>

			<!-- <h4 style="color: #db971b;">Date / Time</h4>
			<p>
				The event begins with lunch starting at <b>12:00 noon</b> on <b>Wednesday, October 25</b>, and ends with lunch finishing up by <b>1:00 pm</b> on <b>Thursday, October 26</b>.
			</p> -->

			<h4 style="color: #db971b;">Guest Speakers</h4>
			<p>
				<ul>
					<li><b>Nick Vujicic</b> with <i>Life Without Limbs</i> - Keynote Speaker on Revival</li>
					<li><b>Ford Taylor</b> with <i>Transformational Leadership</i> - Interactive Transformational Experience</li>
					<li><b>Kevin Freeman</b> with <i>Economic War Room</i> - PANEL: Values-Centric Economy</li>
					<li><b>Lance Wallnau</b> with <i>Lance Learning</i> with an AWAKENING message</li>
					<li>Leaders and Participants in the City Impact Initiatives and Collectives - Updates and Testimonies</li>
				</ul>
			</p>

			<h4 style="color: #db971b;">Exclusive Experience</h4>
			<p>
				<ul>
					<li>Networking Opportunity</li>
					<li>Meals Provided</li>
				</ul>
			</p>

			<h4 style="color: #db971b;">Hotel Information</h4>
			<p>
				We have a limited number of rooms reserved through <b>Monday, October 16</b> at <b>The Westin at DFW Airport</b>. The special event rate is <b>$199/night plus tax</b>. After you complete your registration, you will see a link to reserve a room at this special rate directly from the hotel. 
			</p>
			<!-- <p>
				<div style="margin: 0 0 0 1em;"><a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1691437301857&key=GRP&app=resvlink" target="_blank"><u>Register your hotel room here</u></a>.</div>
			</p> -->

			<h4 style="color: #db971b;">Airport Information</h4>
			<p>
				The DFW Airport is 5 minutes (3.2 mi) from The Westin at Dallas Fort Worth Airport Hotel.  If you require a shuttle, please share your travel plans with the hotel so they can accommodate your arrival needs.
			</p>

			<h4 style="color: #db971b;">Useful Links</h4>
			Event Registration:<div style="margin: 0 0 0.5em 1em;"><a href="https://gapmovement.com/oct2023"><u>gapmovement.com/oct2023</u></a></div>
			The Great Awakening Project Info:<div style="margin: 0 0 0.5em 1em;"><a href="https://gapmovement.com"><u>gapmovement.com</u></a></div>
			Hotel Amenities:<div style="margin: 0 0 0.5em 1em;"><a href="https://www.marriott.com/en-us/hotels/dfwwa-the-westin-dallas-fort-worth-airport/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0" target="_blank"><u>The Westin at DFW Airport</u></a></div>

			<h4 style="color: #db971b;">Questions</h4>
			<p>
				Should you have questions, please contact Jenny Limcher at (713) 819-9872 or <a href="mailto: jennyl@hartmanfamilytrust.com"><u>JennyL@HartmanFamilyTrust.com</u></a>.
			</p>
		</div>
	</div>

	<div class="content-page-inner registration-content">

		<div v-if="registrationClosed" class="registration-sent">
			<h2>Registration Closed</h2>
			<p></p>
			<p>Registration for the {{ eventTitle }} is now closed.</p>
			<p>Please reach out to our Events Team at <a href="mailto:events@gapmovement.com">events@gapmovement.com</a> if you have any questions or need anything else.</p>
		</div>

		<div v-else-if="registrationSent" class="registration-sent">
			<h2>Registration Complete!</h2>
			<p>Thank you for registering for the {{ eventTitle }}!</p>

			<h3 style="color: #933b0f;">Reserve your room</h3>
			<p>
				We have a limited number of rooms reserved at <b>The Westin at DFW Airport</b>. The special event rate for these rooms is <b>$199/night plus tax</b>. Both King and Double Queen rooms are available. Each registrant is <b>responsible for his/her own room payment</b> upon checkout. 
			</p>

			<p>
				<div style="margin: 0 0 0 1em;"><a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1691437301857&key=GRP&app=resvlink" target="_blank"><u>Reserve your hotel room here</u></a>.</div>
			</p>

			<p>If you have any questions, or if you don't receive an email confirmation, please reach out to our Events Team at <a href="mailto:events@gapmovement.com">events@gapmovement.com</a></p>
			<p>See you at the event!</p>

		</div>

		<form v-else class="registration-form">
			<h3 style="color: #933b0f;">Event begins at <u>noon on Wednesday, October 25</u>, and ends at <u>1 PM after lunch on Thursday, October 26</u>.</h3>
			<h2>Register</h2>

			<label for="name">Your Name</label>
			<input type="text" id="name" name="name" v-model="registration.full_name">
			<div class="two-columns">
				<div>
					<label for="email">Email Address</label>
					<input type="text" id="email" name="email" v-model="registration.email_address">
				</div>
				<div>
					<label for="phone">Phone Number</label>
					<input type="tel" id="phone" name="phone" v-model="registration.phone_number">
				</div>
			</div>

			<template v-if="registration.amount > 0">
				<label for="creditcard">Card Number</label>
				<input type="text" id="creditcard" name="creditcard" v-model="registration.card_number">
				<div class="two-columns">
					<div>
						<label for="expiry">Expiration Date</label>
						<div>
							<select class="input-half" v-model="registration.expiration_month">
								<option value="">Month...</option>
								<option v-for="(month, index) in expirationMonths" :value="month">{{ month }}</option>
							</select>
							<select class="input-half" v-model="registration.expiration_year">
								<option value="">Year...</option>
								<option v-for="(year, index) in expirationYears" :value="year">{{ year }}</option>
							</select>
						</div>
					</div>
					<div>
						<label for="cvv">C V V (back of card)</label><br>
						<input type="text" id="cvv" name="cvv" class="input-half" v-model="registration.cvv">
					</div>
				</div>
			</template>

			<label for="billing">{{ registration.amount > 0 ? 'Billing ' : '' }}Address</label>
			<input type="text" id="billing" name="billing" v-model="registration.address_1">
			<label for="city">City</label>
			<input type="text" id="city" name="city" v-model="registration.city">
			<div class="two-columns">
				<div>
					<label for="state">State</label>
					<state-select :selectedState="registration.state" @updateSelected="updateSelectedState"></state-select>
				</div>
				<div>
					<label for="zip">Zip Code</label>
					<input type="text" id="zip" name="zip" v-model="registration.postal_code">
				</div>
			</div>

			<div class="divider"></div>
			<label>Accompanied by Spouse or Guest Name</label>
			<input type="text" id="name" name="name" v-model="registration.guest_full_name">
			<div class="two-columns">
				<div>
					<label for="email">Spouse or Guest Email</label>
					<input type="text" id="email" name="email" v-model="registration.guest_email_address">
				</div>
				<div>
					<label for="phone">Spouse or Guest Phone</label>
					<input type="tel" id="phone" name="phone" v-model="registration.guest_phone_number">
				</div>
			</div>

			<div class="divider"></div>
			<div class="three-columns">
				<div>
					<label>Arrival Date</label>
					<div style="margin: 0 0 1em 0.5em;">
						<input type="radio" name="arrival_date" v-model="registration.arrival_date" value="10/24"/> October 24<br>
						<input type="radio" name="arrival_date" v-model="registration.arrival_date" value="10/25"/> October 25<br>
						<input type="radio" name="arrival_date" v-model="registration.arrival_date" value="10/26"/> October 26<br>
						<div style="margin: 0.1em 0 0 1.33em;">
							<label>Other</label>
							<input type="date" name="arrival_date_other" v-model="registration.arrival_date_other" style="font-size: 0.8em;">
						</div>
					</div>
				</div>
				<div style="margin: 0 0 1em 0.5em;">
					<label>Departure Date</label>
					<div>
						<input type="radio" name="departure_date" v-model="registration.departure_date" value="10/25" :disabled="registration.arrival_date == '10/26'" /> October 25<br>
						<input type="radio" name="departure_date" v-model="registration.departure_date" value="10/26"/> October 26<br>
						<input type="radio" name="departure_date" v-model="registration.departure_date" value="10/27"/> October 27<br>
						<div style="margin: 0.1em 0 0 1.33em;">
							<label>Other</label>
							<input type="date" name="departure_date_other" v-model="registration.departure_date_other" style="font-size: 0.8em;">
						</div>
					</div>
				</div>
				<div>
					<label for="notes">Any food allergies?</label>
					<textarea id="notes" name="notes" v-model="registration.food_allergies"></textarea>
				</div>
			</div>

			<div class="divider"></div>
			<div class="two-columns">
				<div>
					<label for="interest">Which cultural area(s) are you interested/involved in most?</label>
					<div v-for="(interest, index) in interestOptions">
						<input type="checkbox" v-model="interest.selected" /> {{ interest.description }}
					</div>
					<div style="margin: 0 0 0 1.25em;">
						<label>Other</label>
						<textarea name="interest_other" v-model="interestOptions.other"></textarea>
					</div>
				</div>
				<div>
					<label for="interest">Who told you about this event?</label>
					<textarea name="how_heard" v-model="registration.how_heard"></textarea>
					<label for="notes">Any other comments or questions?</label>
					<textarea id="notes" name="notes" v-model="registration.notes"></textarea>
					<label>Will you be staying at The Westin for this event?</label><br>
					<input type="radio" name="staying_at_westin" v-model="registration.staying_at_westin" value="Yes" /> Yes
					<input type="radio" name="staying_at_westin" v-model="registration.staying_at_westin" value="No"/> No
					<div v-if="registration.staying_at_westin == 'Yes'" style="margin: 1em 0 0 1em;">Finish this registration to see a special link to reserve your room at the hotel.</div>
				</div>
			</div>

			<div class="two-columns controls">
				<button :class="['button-dark', { disabled: sending }]" @click.prevent="sendRegistration()"><font-awesome-icon v-if="sending" icon="spinner" class="fa-spin" /><span v-else>{{ registration.amount > 0 ? 'Authorize $' + registration.amount : 'Send' }} Registration</span></button>
				<div class="message">{{ message }}</div>
			</div>

		</form>

	</div>

	<!-- <modal v-if="disableRegistration" /> -->

</div>
</template>

<script>
import StateSelect from '@/components/StateSelect.vue'
import Modal from '@/components/Modal.vue'
export default {
	name: 'Register',
	components: {
		'state-select': StateSelect,
		'modal': Modal
	},
	data () {
		return {
			message: '',
			eventTitle: '',
			useEventTitle: false,
			eventDescription: '',
			registrationSuccessMessage: 'Your registration has been sent -- see you at the event!',
			howHeard: [],
			interestOptions: [],
			registration: {
				event_code: '',
				sponsorship_code: '',
				amount: '',
				leadership_level: '',
				email_address: '',
				phone_number: '',
				full_name: '',
				address_1: '',
				city: '',
				state: '',
				postal_code: '',
				card_number: '',
				expiration_month: '',
				expiration_year: '',
				cvv: '',
				guest_full_name: '',
				guest_email_address: '',
				guest_phone_number: '',
				arrival_date: '',
				departure_date: '',
				food_allergies: '',
				interested_in: [],
				how_heard: '',
				notes: '',
				staying_at_westin: '',
				form_type: 'EVENT_REGISTRATION_FORM'
			},
			sending: false,
			registrationSent: false,
			registrationClosed: true,
			today: ''
		}
	},
	created() {
		let dateObject = new Date()
		this.today = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1).toString().padStart(2,'0') + '-' + dateObject.getDate().toString().padStart(2,'0')
		if (this.today <= '2023-10-26') {
			this.registrationClosed = false
		}
		if (this.$route.query.event_code) {
			this.registration.event_code = this.$route.query.event_code
		} else {
			// we're here from an aliased route
			let pathParts = this.$route.matched[0].path.split('/')
			this.registration.event_code = pathParts.pop()
		}
		if (!this.registration.event_code) {
			this.$router.push('/page-not-found')
		}
		window.scrollTo(0, 0);
		this.getSpheres()
		this.getEvent()
	},
	methods: {
		getSpheres() {
			// for registration, GAP wants custom interest options, not spheres
			this.interestOptions = [
				{ description: 'Church/Ministry/Prayer' },
				{ description: 'Family' },
				{ description: 'Government/Public Services/Elections' },
				{ description: 'Business/Economics' },
				{ description: 'Education' },
				{ description: 'Media/Social Media' },
				{ description: 'Arts/Entertainment' },
				{ description: 'Sports' },
				{ description: 'Music' },
				{ description: 'Health' }
			]
			// this.apiCall({ destination: 'get_list_spheres' }).then(obj => {
			//     if (obj.status == 'success') {
			//         this.interestOptions = obj.spheres
			//         this.getEvent()
			//     } else {
			//         this.message = obj.message
			//     }
			// })
		},
		getContactOptions() {
			this.apiCall({ destination: 'get_contact_form_options' }).then(obj => {
				if (obj.status == 'success') {
					this.howHeard = obj.how_heard
				} else {
					this.message = obj.message
				}
			})
		},
		getEvent() {
			this.apiCall({ destination: 'get_item_event', data: { event_code: this.registration.event_code } }).then(obj => {
				if (obj.status == 'success') {
					if (obj.event.detailed_description.indexOf(obj.event.description) > -1) {
						this.useEventTitle = true
					}
					this.eventTitle = obj.event.description
					this.eventDescription = obj.event.detailed_description
				} else {
					this.message = obj.message
				}
			})
		},
		updateSelectedState(state) {
			this.registration.state = state
		},
		sendRegistration() {
			if (this.sending) { return false }
			this.registration.interested_in = []
			for (const interest of this.interestOptions){
				if (interest.selected) {
					this.registration.interested_in.push(interest.description)
				}
			}
			if (this.interestOptions.other) {
				this.registration.interested_in.push('Other: ' + this.interestOptions.other)
			}
			this.message = ''
			let validateMessage = ''
			if (!validateMessage && !this.registration.full_name) {
				validateMessage = 'Please enter your name.'
			}
			if (!validateMessage && !this.isValidEmailAddress(this.registration.email_address)) {
				validateMessage = 'Please enter a full email address.'
			}
			if (!validateMessage && !this.isValidPhoneNumber(this.registration.phone_number)) {
				validateMessage = 'Please enter a ten-digit phone number.'
			}
			if (!validateMessage && !this.registration.address_1) {
				validateMessage = 'Please enter ' + (this.registration.amount > 0 ? 'the Billing' : 'your') + ' Address.'
			}
			if (!validateMessage && !this.registration.city) {
				validateMessage = 'Please enter ' + (this.registration.amount > 0 ? 'the Billing' : 'your') + ' City.'
			}
			if (!validateMessage && !this.registration.state) {
				validateMessage = 'Please enter ' + (this.registration.amount > 0 ? 'the Billing' : 'your') + ' State.'
			}
			if (!validateMessage && !this.registration.postal_code) {
				validateMessage = 'Please enter ' + (this.registration.amount > 0 ? 'the Billing' : 'your') + ' Zip Code.'
			}
			if (!validateMessage && this.registration.interested_in.length < 1) {
				validateMessage = 'Please select one or more cultural areas you are interested in.'
			}
			if (!validateMessage && this.registration.amount > 0 && !this.isValidCreditCardNumber(this.registration.card_number)) {
				validateMessage = 'Please enter a valid Card Number.'
			}
			if (!validateMessage && this.registration.amount > 0 && (!this.registration.expiration_month || !this.registration.expiration_year)) {
				validateMessage = 'Please enter the Expiration Date.'
			}
			if (!validateMessage && this.registration.amount > 0 && !this.registration.cvv) {
				validateMessage = 'Please enter the CVV card code.'
			}
			if (!validateMessage && !this.registration.arrival_date && !this.registration.arrival_date_other) {
				validateMessage = 'Please select your arrival date.'
			}
			if (!validateMessage && !this.registration.departure_date && !this.registration.departure_date_other) {
				validateMessage = 'Please select your departure date.'
			}
			if (!validateMessage && !this.registration.staying_at_westin) {
				validateMessage = 'Please tell us if you are staying at The Westin.'
			}			
			if (validateMessage) {
				this.message = validateMessage
				return false
			}
			// extra data is stored as json in registrations table
			this.registration.extra_data = {
				interested_in: this.registration.interested_in,
				how_heard: this.registration.how_heard,
				guest_full_name: this.registration.guest_full_name,
				guest_email_address: this.registration.guest_email_address,
				guest_phone_number: this.registration.guest_phone_number,
				arrival_date: this.registration.arrival_date,
				arrival_date_other: this.registration.arrival_date_other,
				departure_date: this.registration.departure_date,
				departure_date_other: this.registration.departure_date_other,
				food_allergies: this.registration.food_allergies,
				staying_at_westin: this.registration.staying_at_westin
			}
			this.message = 'Sending your registration...'
			this.sending = true
			this.apiCall({ destination: 'save_registration', data: this.registration }).then(obj => {
				if (obj.status == 'success') {
					this.registrationSent = true
					let element = document.getElementById('page_top');
					let elementTop = element.offsetTop;
					window.scrollTo({ left: 0, top: elementTop , behavior: 'smooth' });
				} else {
					this.message = obj.message
					this.sending = false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
div.content-page {
	display: grid;
	grid-template-columns: 1fr;
	@media all and (min-width: 960px) {
		grid-template-columns: 1fr 2fr;
	}
	div.content-sidebar {
		height: auto;
	}
	div.event-description {
		padding: 1.5em;
		text-align: left;
		@media all and (min-width: 960px) {
			padding: 1em 4em 1em 4em;
		}
		ul { 
			padding: 0 0 0 0.8em; 
		}
	}
	.registration-content {
		padding: 1.5em;
		text-align: left;
		@media all and (min-width: 960px) {
			padding: 1em 4em 1em 4em;
		}
		p { margin: 1em 0; }
		h2 {
			margin: 0 0 0.5em 0;
		}
		h3 {
			margin: 0 0 0.5em 0;
		}
		.change-leadership-level {
			font-size: 0.8em;
			cursor: pointer;
		}

	}
}
.controls {
	margin: 1em 0;
	align-items: center;
}
.registration-sent {
	height: 23em;
}
.contact-info {
	border-top: 1px solid #ccc;
	margin: 1em 0 0 0;
	font-size: 0.8em;
}
.leadership-level {
	display: grid;
	grid-template-columns: 2em 1fr;
	margin: 0 0 0.5em 0;
	.checkbox {
		display: inline-block;
		width: 1.5em;
		height: 1.5em;
		border: 2px solid #666;
		text-align: center;
		cursor: pointer;
	}

	ul.leadership-level-list {
		display: none;
		font-size: 0.9em;
		margin: 0 0 1em 1.5em;
	}
	ul.leadership-level-list.active {
		display: inherit;
	}
}
</style>
